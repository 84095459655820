<template>
  <div>
    <div class="flexCol">
      <div class="topPosition">
        <div class="flexInput" ref="flexInput" v-if="!showRoute">
          <img :src="langImg" alt="" class="lang" @click="switchLang" />
          <van-field left-icon="search" @focus="focusSearch" @clear="clearPositionCurrent" v-model="searchSite" clearable :placeholder="$t('bus.search_stops')" class="bgGray bgGrayInput" />
        </div>
      </div>
      <div>
        <van-overlay v-if="completeListShow" :show="completeList.length ? true : false" :z-index="9999" :custom-style="{ top: inputHeight + 'px' }" />
        <div class="searchList" :style="'top:' + inputHeight + 'px'" v-if="completeListShow">
          <div v-for="(list, index) in completeList" :key="index" @click="positionCurrent(list)">
            <div v-if="list.address != ''" class="searchAddress">
              <div class="font14 color3 mb-4 fontWeight">
                <div v-html="filterName(list.name)"></div>
              </div>
              <div class="color6 font12 text-overflow">
                {{ list.district }}<span v-if="list.address != ''">{{ list.address }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mapBox" class="mapBox"></div>
      <div v-if="showRoute" class="lineNav" :style="'top:16px'">
        <div class="nav">
          <div class="navFlex1">
            <div>
              <span class="font16 color3 fontWeight">{{ currentRouteLine.title }}</span>
              <span class="labelTag ml-8">{{ mapList.length }} {{ $t("bus.stops") }}</span>
            </div>
          </div>
          <div class="lineBtn">
            <span :class="schoolStatus == 1 ? 'buttonSelect' : 'ponBtn'" @click="tabPositon(1)">{{ $t("bus.pick-up") }}</span>
            <span :class="schoolStatus == 2 ? 'buttonSelect' : 'ponBtn'" @click="tabPositon(2)">{{ $t("bus.drop-off") }}</span>
          </div>
        </div>
        <div class="lineDetail" v-if="lineDetail">
          <van-icon name="clear" class="close" @click="closeDetail()" />
          <div class="overflow" style="max-height: 210px">
            <div class="flexLine">
              <span class="line"></span>
              <div v-for="(list, i) in mapList" :key="i" class="flex" :class="[siteLineShow == list.id ? 'currentColor' : '', i !== 0 ? 'mt-8' : '']" @click="lineDetailClick(list, i)">
                <span class="number">{{ i + 1 }}</span>
                <span class="time" :class=" list.type==2?'color9':'color6'">{{ list.type==2?"---":schoolStatus == 1 ? list.pickup_time : list.dropoff_time }}</span>
                <span class="flex1">{{ list.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btoLogo">
        <div class="flex1">
          <div class="site" @click="viewSite">
            <img src="https://m2.files.ivykids.cn/cloud01-file-8025768FgU1Y5Yh8a_LL569RQ5U9KhQ3T-w.png" alt="" />
            <span class="siteText">{{ $t("bus.Stops") }}</span>
            <span class="siteNum">{{ station_list_box.length }}</span>
          </div>
        </div>
        <div class="logo" @click="outLine"><img src="https://m2.files.ivykids.cn/cloud01-file-8025768FjCVgKxdijlXZgAox_qsfzQMt6lS.png" alt="" /></div>
        <div class="flex1">
          <div class="site" @click="viewLine">
            <img src="https://m2.files.ivykids.cn/cloud01-file-8025768FhN-xLFcUi_FLutPTcaK__pcA1aE.png" alt="" />
            <span class="siteText">{{ $t("bus.Routes") }}</span>
            <span class="siteNum">{{ lineList.length }}</span>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showSite" position="left" :style="{ height: '100%', width: '80%', overflow: 'hidden' }">
      <div class="siteList flex-column">
        <div class="titleCol">
          <div class="title">{{ $t("bus.stops_total", { t: station_list_box.length }) }}</div>
          <van-field v-model="searchQuery" left-icon="search" clearable :placeholder="$t('bus.exsiting_stops')" class="bgGray bgGrayInput" />
        </div>
        <div v-if="filteredList.length" class="filteredList">
          <div class="filteredListFlex">
            <div class="list" v-for="(list, index) in filteredList" :key="index" @click="clickSite(index, 'list')">
              <div class="font14 color3 mb-8">{{ list.title }}</div>
              <div>
                <span class="labelTag" v-for="(line, i) in list.lineText" :key="i">{{ line.title }}</span>
                <!-- <span class="labelTag">{{ list.code }}</span> -->
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty image="error" :description="$t('calendar.no_data')" />
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showLine" position="left" :style="{ height: '100%', width: '80%' }">
      <div class="siteList">
        <div class="title mb-25">{{ $t("bus.routes_total", { t: lineList.length }) }}</div>
        <div v-if="lineList.length">
          <van-row gutter="16">
            <van-col span="12" v-for="(list, index) in lineList" :key="index">
              <div class="list textCenter" @click="linePolyline(list)">
                <div class="font14 color3">{{ list.title }}</div>
                <div class="color6 font12 mt-4">{{ $t("bus.line_stops", { t: route2Site[list.id].length }) }}</div>
              </div>
            </van-col>
          </van-row>
        </div>
        <div v-else>
          <van-empty image="error" :description="$t('calendar.no_data')" />
        </div>
      </div>
    </van-popup>
    <van-popup v-model="siteDetail" :overlay="false" :round="true" class="siteDetail-popup">
      <div class="siteDetail" v-if="point != '' && station_list_box[point - 1]">
        <van-icon name="cross" class="close" @click="comment('out')" />
        <div class="font16 color3 mb-5 fontWeight">{{ station_list_box[point - 1].title }}</div>
        <div class="font12 color6 mb-8">{{ station_list_box[point - 1].address }}</div>
        <div class="mb-12">
          <span class="labelTag" v-for="(line, i) in station_list_box[point - 1].lineText" :key="i">{{ line.title }}</span>
          <!-- <span class="labelTag">{{ station_list_box[point - 1].code }}</span> -->
        </div>
        <!-- <div class="color6 font12 mb-8"><van-icon name="location" /><span class="ml-5">停车位置</span></div> -->
        <div class="mb-8">
          <span class="label">{{ $t("bus.pick-up_point") }}</span> <span class="font14 color3 fontWeight ml-5">{{ station_list_box[point - 1].pickup_point }}</span>
        </div>
        <div class="">
          <span class="label">{{ $t("bus.drop-off_point") }}</span> <span class="font14 color3 fontWeight ml-5">{{ station_list_box[point - 1].dropoff_point }}</span>
        </div>
        <div class="mt-12 flex">
          <div class="flex1"></div>
          <van-button round type="info" v-if="station_list_box[point - 1].lineText.length == 1" @click="pointLineView(station_list_box[point - 1].id, station_list_box[point - 1].lineText[0],station_list_box[point - 1].siteLineType)" color="#4396FF" size="small">{{ $t("bus.view_routes") }}</van-button>
          <van-button round type="info" v-else @click="slectLineView(station_list_box[point - 1])" color="#4396FF" size="small">{{ $t("bus.view_routes") }}</van-button>
        </div>
      </div>
    </van-popup>
    <van-action-sheet v-model="selectLineshow" :description="$t('bus.check_routes')" :actions="actions" :cancel-text="$t('global.cancel')" close-on-click-action @select="onSelect" />
  </div>
</template>

<script>
import Vue from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import { indexList } from "@/api/bus";
import { Field, Toast, Uploader, Dialog, Popup, Overlay, ActionSheet } from "vant";
import store from "../store";
import storage from "store";
import i18n from "../i18n";
import { setDocumentTitle } from "@/plugins/domUtil";
import { APP_LANGUAGE } from "@/store/mutation-types";
Vue.use(ActionSheet);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Uploader);
Vue.use(Dialog);
Vue.use(Overlay);
Vue.use(Popup);
export default {
  name: "HomeView",
  inject: ["reload"],
  data() {
    return {
      markers: [],
      siteDetail: false,
      showSite: false,
      showLine: false,
      map: null,
      marker: "",
      geoCoder: null,
      AutoComplete: null,
      ToolBar: null,
      keywords: "",
      form: {
        lng: "119.793098",
        lat: "31.91243",
        address: "",
      },
      loading: false,
      options: [],
      geolocation: null,
      mapList: [],
      center: [116.397428, 39.90923],
      startPosition: null, // 起点坐标
      endPosition: null, // 终点坐标
      path: [], // 驾车路线路径
      point: "",
      showD: false,
      allmarkers: [],
      station_list: [], //全部站点
      lineList: [], //全部路线
      route2Site: {},
      routeLine: {}, //当前线路
      lineMarker: [], //当前线路点
      schoolStatus: null, //上学还是放学
      lat: "", //上学放学字段
      lon: "", //上学放学字段
      pointLine: "",
      pointTime: "",
      searchQuery: "",
      searchSite: "",
      completeList: [],
      completeListShow: false,
      inputHeight: "",
      positionMarker: [],
      currentRouteLine: {},
      showRoute: false,
      searchMarker: {},
      zooms: "",
      startMapList: [],
      endMapList: [],
      infoWindow: null,
      langImg: "",
      locale: i18n.locale,
      siteLineShow: "",
      selectLineshow: false,
      actions: [],
      multipleLine: {},
      route2SiteDrop: {},
      school_id: "",
      start_year: "",
      lineDetail: false,
      station_list_box: [],
      Geolocation: null,
    };
  },
  created() {
    this.langImgTab();
    this.school_id = this.$route.query.school_id;
    this.start_year = this.$route.query.start_year || 0;
  },
  beforeDestroy() {},
  computed: {
    filteredList() {
      return this.station_list_box.filter((item) => {
        return item.title.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  watch: {
    searchSite(val) {
      let that = this;
      if (val == "") {
        that.completeList = [];
        return;
      }
      var autoOptions = {
        city: "北京",
        lang: that.locale == "zh" ? "zh_cn" : "en",
      };
      var autoComplete = new AMap.AutoComplete(autoOptions);
      autoComplete.search(val, function (status, result) {
        if (status === "complete" && result.info === "OK") {
          that.completeList = result.tips;
        }
      });
    },
  },
  mounted() {
    AMapLoader.load({
      key: "a34ec0a9d808c39f9aea30362670ef78",
      version: "2.0",
      plugins: ["AMap.Geocoder", "AMap.ToolBar", "AMap.Geolocation", "AMap.Driving", "AMap.AutoComplete"],
    })
      .then((AMap) => {
        this.map = new AMap.Map("mapBox", {
          zoom: 14,
          center: [116.485492, 40.04229],
          resizeEnable: true,
          liteMode: true,
          renderer: "canvas",
        });
        this.getIndex();
        this.geoCoder = new AMap.Geocoder({
          city: "010", //城市设为北京，默认：“全国”
          radius: 500, //范围，默认：500
        });
        this.ToolBar = new AMap.ToolBar({
          position: {
            bottom: "70px",
            right: "15px",
          },
        });
        this.map.addControl(this.ToolBar);
        const icons = {
          default: new AMap.Icon({
            image: "https://m2.files.ivykids.cn/cloud01-file-8025768FsD4QWs83xI8_M5fSH4uLqbUq_sy.png",
            size: new AMap.Size(15, 15),
            imageSize: new AMap.Size(15, 15),
          }),
          large: new AMap.Icon({
            image: "https://m2.files.ivykids.cn/cloud01-file-8025768FimXp_5hCVp8GYtq4Ogx-0i5phaa.png",
            size: new AMap.Size(25, 25),
            imageSize: new AMap.Size(25, 25),
          }),
          big: new AMap.Icon({
            image: "https://m2.files.ivykids.cn/cloud01-file-8025768FimXp_5hCVp8GYtq4Ogx-0i5phaa.png",
            size: new AMap.Size(40, 40),
            imageSize: new AMap.Size(40, 40),
          }),
        };
        this.map.on("zoomchange", () => {
          this.zooms = this.map.getZoom();
          this.map.setZooms([10, 20]);
          if (!this.showRoute && !this.siteDetail && this.station_list_box.length) {
            if (this.zooms >= 14) {
              this.setMarkerContent();
            } else {
              let iconToUse;
              if (this.zooms >= 13.5) {
                iconToUse = icons.big;
              } else if (this.zooms >= 13) {
                iconToUse = icons.large;
              } else {
                iconToUse = icons.default;
              }
              this.updateMarkersIcon(iconToUse);
            }
          } else {
            if (this.siteDetail) {
              this.map.setZooms([14, 20]);
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    updateMarkersIcon(icon) {
      this.allmarkers.forEach((marker) => {
        marker.setIcon(icon);
        marker.setContent("");
      });
    },
    langImgTab() {
      this.langImg = i18n.locale == "zh" ? "https://m2.files.ivykids.cn/cloud01-file-8025768Fs2UF6Y0rbCNXj7DsyuuV_KqK3yf.png" : "https://m2.files.ivykids.cn/cloud01-file-8025768Fm60NHQALCta6r99MQV-U1rZ0J8X.png";
    },
    switchLang() {
      let cuLang = storage.get(APP_LANGUAGE) || "zh";
      i18n.locale = cuLang == "zh" ? "en" : "zh";
      this.langImgTab();
      store.dispatch("setLang", i18n.locale);
      this.$route.meta && typeof this.$route.meta.title !== "undefined" && setDocumentTitle(i18n.t("router." + this.$route.meta.title));
      this.reload();
    },
    findAndCombine(stations, lineDetails) {
      return stations.map((station) => {
        let lines = Object.keys(this.route2Site).filter((lineKey) => this.route2Site[lineKey].includes(station.id));
        let siteLineType = 1;
        if (lines.length === 0) {
          lines = Object.keys(this.route2SiteDrop).filter((lineKey) => this.route2SiteDrop[lineKey].includes(station.id));
          siteLineType = 2;
        }
        const lineText = lineDetails.filter((detail) => lines.includes(detail.id.toString()));

        return {
          ...station,
          lineText,
          siteLineType: siteLineType,
          line: lines.map(Number), // 将线路ID转换为数字类型
        };
      });
    },
    getIndex() {
      indexList({ school_id: this.school_id, start_year: this.start_year }).then((res) => {
        let siteData = res.data.siteData.filter((list) => list.type == 1);
        this.route2Site = res.data.route2Site;
        this.route2SiteDrop = res.data.route2SiteDrop;
        this.station_list_box = this.findAndCombine(siteData, res.data.routeData);
        this.station_list = this.findAndCombine(res.data.siteData, res.data.routeData);
        this.lineList = res.data.routeData;

        this.defaultSite(false);
        this.$nextTick(() => {
          this.inputHeight = this.$refs.flexInput.offsetHeight;
        });
      });
    },
    focusSearch() {
      this.completeListShow = true;
    },
    filterName(name) {
      if (this.searchSite != "") {
        const regex = new RegExp(`(${this.searchSite})`, "gi");
        const highlighted = name.replace(regex, '<span style="color: #4D88D2">$1</span>');
        return highlighted;
      }
    },
    viewSite() {
      this.showSite = true;
    },
    viewLine() {
      this.showLine = true;
    },
    outLine() {
      this.siteDetail = false;
      this.showRoute = false;
      this.removeMap();
      this.defaultSite(true);
      if (this.infoWindow) {
        this.infoWindow.close();
      }
    },
    defaultSite() {
      this.allmarkers = [];
      let that = this;
      var Icon = new AMap.Icon({
        image: "https://m2.files.ivykids.cn/cloud01-file-8025768FsD4QWs83xI8_M5fSH4uLqbUq_sy.png",
        size: new AMap.Size(15, 15),
        imageSize: new AMap.Size(15, 15),
      });
      for (let i = 0; i < that.station_list_box.length; i++) {
        let markers = new AMap.Marker({
          icon: Icon,
          position: new AMap.LngLat(that.station_list_box[i].pickup_longitude, that.station_list_box[i].pickup_latitude),
          extData: { index: i },
          anchor: "bottom-center",
        });
        that.showD = false;
        markers.on("click", (e) => {
          if (that.showD) {
            that.showD = false;
            return false;
          }
          that.showD = true;
          let item = e.target.getExtData();
          this.clickSite(item.index);
        });
        markers.setMap(this.map);
        this.allmarkers.push(markers);
      }
      if (this.Geolocation == null) {
        this.getLocalInfo();
      } else {
        this.map.setFitView(this.allmarkers);
      }
    },
    getLocalInfo() {
      this.Geolocation = new AMap.Geolocation({
        zoomToAccuracy: true,
        enableHighAccuracy: true,
        imeout: 10000,
        position: {
          bottom: "30px",
          right: "15px",
        },
      });
      this.map.addControl(this.Geolocation);
      this.Geolocation.getCurrentPosition(function (status, result) {
        if (status === "complete") {
          this.map.setZoomAndCenter(14.4, [result.position.getLng(), result.position.getLat()]);
        } else {
          this.map.setFitView(this.allmarkers, true);
        }
      });
    },
    clearPositionCurrent() {
      this.map.remove(this.searchMarker);
    },
    positionCurrent(list) {
      this.outLine();
      this.map.remove(this.searchMarker);
      let Icon = new AMap.Icon({
        image: "https://m2.files.ivykids.cn/cloud01-file-8025768FufdzWUyQqsvnEwdWcwqPXpO8d8f.png",
        size: new AMap.Size(40, 40),
        imageSize: new AMap.Size(40, 40),
      });
      let marker = new AMap.Marker({
        icon: Icon,
        position: new AMap.LngLat(list.location.lng, list.location.lat),
        anchor: "bottom-center",
      });
      marker.setMap(this.map);
      this.comment(marker);
      this.searchMarker = marker;
      this.siteDetail = false;
      this.completeListShow = false;
      this.searchSite = list.name;
    },
    setMarkerContent() {
      let that = this;
      this.allmarkers.forEach(function (marker, index) {
        let markerContent = "" + '<div class="comment comment' + (index + 1) + ' custom-content-marker" style="margin-left:20px"><div class="textCenter">' + '<span class="img"><span></span></span>' + '<span class="close-btn" style="max-width: 120px;"><span>' + that.station_list_box[index].title + "</span></span>" + "</div></div>";
        marker.setContent(markerContent);
      });
    },
    comment(type) {
      if (type == "out") {
        this.siteDetail = false;
        this.point = "";
        this.map.setZooms([10, 20]);
      } else {
        this.map.setFitView(
          [type], // 覆盖物数组
          true, // 动画过渡到制定位置
          [60, 260, 60, 60],
          15
        );
      }
      var comments = document.querySelectorAll(".comment");
      if (comments.length) {
        comments.forEach(function (comment) {
          comment.classList.replace("custom-content-marker-add", "custom-content-marker");
        });
      }
    },
    clickSite(index, type) {
      this.map.remove(this.lineMarker);
      this.map.remove(this.positionMarker);
      this.map.remove(this.searchMarker);
      this.map.remove(this.routeLine);
      if (this.infoWindow) {
        this.infoWindow.close();
      }
      this.searchSite = "";
      this.showSite = false;
      if (this.showRoute) {
        this.showRoute = false;
        this.defaultSite();
      }
      let point = index + 1;
      if (!this.siteDetail && this.zooms < 14) {
        this.setMarkerContent();
      }
      this.comment(this.allmarkers[index]);
      const textCenter = document.getElementsByClassName("comment" + point);
      if (!textCenter) return;
      if (type) {
        this.point = point;
        textCenter[0].classList.replace("custom-content-marker", "custom-content-marker-add");
        this.siteDetail = true;
      } else {
        if (this.point == point) {
          this.point = "";
          textCenter[0].classList.replace("custom-content-marker-add", "custom-content-marker");
          this.siteDetail = false;
        } else {
          this.point = point;
          textCenter[0].classList.replace("custom-content-marker", "custom-content-marker-add");
          this.siteDetail = true;
        }
      }
    },
    slectLineView(list) {
      this.multipleLine = list;
      this.actions = [];
      for (let i = 0; i < list.lineText.length; i++) {
        this.actions.push({ name: list.lineText[i].title });
      }
      this.selectLineshow = true;
    },
    onSelect(item, index) {
      this.selectLineshow = false;
      this.pointLineView(this.multipleLine.id, this.multipleLine.lineText[index],1);
    },
    pointLineView(id, line,type) {
      this.siteLineShow = id;
      this.showRoute = true;
      this.siteDetail = false;
      this.currentRouteLine = line;
      let ids = this.route2Site[line.id];
      let idsDrop = this.route2SiteDrop[line.id];
      this.startMapList = ids.map((id) => {
        return this.station_list.find((item) => item.id === id);
      });
      this.endMapList = idsDrop.map((id) => {
        return this.station_list.find((item) => item.id === id);
      });
      this.tabPositon(type);
      this.point = "";
    },
    linePolyline(list) {
      this.siteLineShow = "";
      this.searchSite = "";
      this.showRoute = true;
      this.currentRouteLine = list;
      let ids = this.route2Site[list.id];
      let idsDrop = this.route2SiteDrop[list.id];
      this.startMapList = ids.map((id) => {
        return this.station_list.find((item) => item.id === id);
      });
      this.endMapList = idsDrop.map((id) => {
        return this.station_list.find((item) => item.id === id);
      });
      this.showLine = false;
      this.tabPositon(1);
    },
    closeDetail() {
      this.lineDetail = false;
    },
    tabPositon(type) {
      if (this.infoWindow) {
        this.infoWindow.close();
      }
      this.siteDetail = false;
      this.schoolStatus = type;
      if (this.schoolStatus == 1) {
        this.lat = "pickup_latitude";
        this.lon = "pickup_longitude";
        this.pointLine = "pickup_point";
        this.pointTime = "pickup_time";
        this.mapList = this.startMapList;
      } else {
        this.lat = "dropoff_latitude";
        this.lon = "dropoff_longitude";
        this.pointLine = "dropoff_point";
        this.pointTime = "dropoff_time";
        this.mapList = this.endMapList;
      }
      this.lineDetail = true;
      this.removeMap();
      this.drawPolyline();
      this.markPoints();
    },
    createMarkerContent(item) {
      return `<div class="lineSite">
              <div class="lineText">
                <div class="title">${item.title}</div>
                <div class="text">${item[this.pointLine]}</div>
                <div class="text">${item[this.pointTime]}</div>
              </div>
            </div>`;
    },
    markPoints() {
      this.lineMarker = [];
      this.mapList.forEach((item, index) => {
        const markerContent = this.createMarkerContent(item);
        let style = {};
        if (index === 0 || index + 1 === this.mapList.length) {
          // 第一个点
          style = {
            "line-height": "32px",
            "background-color": "#5CB85C",
            width: "32px",
            height: "32px",
            "font-size": "16px",
            color: "#fff",
            "border-radius": "50%",
            padding: "0",
            "text-align": "center",
            border: "1px solid #fff",
            "box-shadow": "0px 2px 6px 0px rgba(0,0,0,0.2)",
          };
        } else {
          // 中间的点
          style = {
            "line-height": "22px",
            "background-color": "#F57B1E",
            width: "22px",
            height: "22px",
            "font-size": "14px",
            color: "#fff",
            "border-radius": "50%",
            padding: "0",
            "text-align": "center",
            border: "1px solid #fff",
            "box-shadow": "0px 2px 6px 0px rgba(0,0,0,0.2)",
          };
        }

        var text = new AMap.Text({
          text: index + 1,
          anchor: "center",
          style: style,
          extData: { index: index },
        });
        text.content = markerContent;
        text.on("click", this.markerClick.bind(this));
        this.lineMarker.push(text);
        text.setPosition(new AMap.LngLat(item[this.lon], item[this.lat]));
        this.map.add(text);
        if (this.siteLineShow == item.id) {
          text.emit("click", { target: text });
        }
      });
      this.map.setFitView(this.lineMarker);
    },
    lineDetailClick(list, index) {
      this.siteLineShow = list.id;
      this.lineMarker[index].emit("click", { target: this.lineMarker[index] });
    },
    markerClick(e) {
      const targetText = e.target;
      const data = e.target.getExtData();
      this.lineMarker.forEach((marker, index) => {
        if (index != 0 && index !== this.mapList.length - 1) {
          const defaultStyle = {
            "line-height": "22px",
            "background-color": "#F57B1E",
            width: "22px",
            height: "22px",
            "font-size": "14px",
            color: "#fff",
            "border-radius": "50%",
            padding: "0",
            "text-align": "center",
            border: "1px solid #fff",
            "box-shadow": "0px 2px 6px 0px rgba(0,0,0,0.2)",
          };
          marker.setStyle(defaultStyle);
        }
      });
      if (data.index != 0 && data.index !== this.mapList.length - 1) {
        const enlargedStyle = {
          "line-height": "32px",
          "background-color": "#F57B1E",
          width: "32px",
          height: "32px",
          "font-size": "16px",
          color: "#fff",
          "border-radius": "50%",
          padding: "0",
          "text-align": "center",
          border: "1px solid #fff",
          "box-shadow": "0px 2px 6px 0px rgba(0,0,0,0.2)",
        };
        targetText.setStyle(enlargedStyle);
      }
      this.infoWindow = new AMap.InfoWindow({
        isCustom: true,
        anchor: "middle-left",
      });
      this.infoWindow.setContent(targetText.content);
      this.infoWindow.open(this.map, targetText.getPosition());
    },
    drawPolyline() {
      let that = this;
      var driving = new AMap.Driving({
        policy: 2,
      });
      var marker = [];
      // 根据起终点经纬度规划驾车导航路线
      this.mapList.forEach((item, index) => {
        if (index != 0 && index < this.mapList.length) marker.push(new AMap.LngLat(item[this.lon], item[this.lat]));
      });
      driving.search(
        new AMap.LngLat(this.mapList[0][this.lon], this.mapList[0][this.lat]),
        new AMap.LngLat(this.mapList[this.mapList.length - 1][this.lon], this.mapList[this.mapList.length - 1][this.lat]),
        {
          waypoints: marker,
        },
        function (status, result) {
          if (status === "complete") {
            if (result.routes && result.routes.length) {
              that.drawRoute(result.routes[0]);
            }
          } else {
            // log.error('获取驾车数据失败：' + result)
          }
        }
      );
    },
    drawRoute(route) {
      var path = this.parseRouteToPath(route);
      var canvasDir = document.createElement("canvas");
      var width = 24;
      canvasDir.width = width;
      canvasDir.height = width;
      var context = canvasDir.getContext("2d");
      context.strokeStyle = "#fff";
      context.lineJoin = "round";
      context.lineWidth = 10;
      context.moveTo(-4, width - 4);
      context.lineTo(width / 2, 6);
      context.lineTo(width + 4, width - 4);
      context.stroke();
      this.routeLine = new AMap.Polyline({
        path: path,
        isOutline: true,
        showDir: true,
        dirImg: canvasDir,
        strokeWeight: 7,
        strokeOpacity: 1,
        strokeColor: "#4396FF",
        lineJoin: "bevel",
      });
      this.map.add(this.routeLine);
      this.map.setFitView(this.routeLine, true, [100, 20, 20, 20]);
    },
    parseRouteToPath(route) {
      var path = [];
      for (var i = 0, l = route.steps.length; i < l; i++) {
        var step = route.steps[i];
        for (var j = 0, n = step.path.length; j < n; j++) {
          path.push(step.path[j]);
        }
      }
      return path;
    },
    removeMap() {
      this.map.remove(this.allmarkers);
      this.map.remove(this.lineMarker);
      this.map.remove(this.positionMarker);
      this.map.remove(this.searchMarker);
      this.map.remove(this.routeLine);
    },
  },
};
</script>
<style lang="less">
#mapBox {
  flex: 1;

  .amap-info-contentContainer {
    display: inline-block;
  }

  .amap-info {
    display: inline-block;
    width: auto;
  }

  .custom-content-marker {
    width: 167px;
    height: 36px;
    position: relative;
    text-align: center;
    transform: translate(-50%, 0);
    .textCenter {
      text-align: center;
      display: inline-block;
      background: #ffffff;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
      border-radius: 28px;
      margin-bottom: 10px;
      padding: 2px;

      .img {
        width: 26px;
        height: 26px;
        background: #4396ff;
        border-radius: 50%;
        text-align: center;
        display: inline-block;

        span {
          background-image: url(https://m2.files.ivykids.cn/cloud01-file-8025768FgU1Y5Yh8a_LL569RQ5U9KhQ3T-w.png);
          background-size: 16px 16px;
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
          margin-top: 5px;
          display: inline-block;
        }
      }

      .close-btn {
        font-size: 12px;
        color: #323233;
        margin: 0 8px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .textCenter::before {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 50%;
      margin-left: -4px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #fff;
    }
  }

  .custom-content-marker-add {
    width: 167px;
    height: 36px;
    text-align: center;
    position: relative;
    transform: translate(-50%, 0);

    .textCenter {
      text-align: center;
      display: inline-block;
      background: #4396ff;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
      border-radius: 28px;
      margin-bottom: 10px;
      padding: 2px;

      .img {
        width: 26px;
        height: 26px;
        background: #fff;
        border-radius: 50%;
        text-align: center;
        display: inline-block;

        span {
          background-image: url(https://m2.files.ivykids.cn/cloud01-file-8025768FphViejWH_Cewjc1FMWzvkKwniB1.png);
          background-size: 16px 16px;
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
          margin-top: 5px;
          display: inline-block;
        }
      }

      .close-btn {
        font-size: 12px;
        color: #fff;
        margin: 0 8px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .textCenter::before {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 50%;
      margin-left: -4px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #4396ff;
    }
  }

  .lineSite {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;

    .lineImg {
      width: 12px;
      height: 12px;
    }

    .startImg {
      width: 25px;
      height: 34px;
    }

    .lineText {
      padding: 4px 8px;
      background: #f57b1e;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      color: #fff;
      margin-left: 24px;

      .title {
        font-size: 12px;
      }

      .text {
        font-size: 11px;
        color: rgba(255, 255, 255, 0.9);
        margin-top: 2px;
      }
    }

    .lineText::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 17px;
      margin-top: -8px;
      border-right: 8px solid #f57b1e;
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;
    }

    .lineTextSelect {
      background: #5cb85c;
    }

    .lineTextSelect::before {
      border-right: 8px solid #5cb85c;
    }

    .start::before {
      left: 28px;
    }
  }

  .amap-logo {
    display: none !important;
    opacity: 0 !important;
  }

  .amap-copyright {
    opacity: 0 !important;
  }
}

.bgGrayInput {
  .van-field__left-icon {
    i {
      color: #c8c9cc;
    }
  }
}
</style>
<style scoped lang="less">
.fontWeight {
  font-weight: 500;
}

.flexCol {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .topPosition {
    .flexInput {
      display: flex;
      align-items: center;
      padding: 0px 16px;
      background: #fff;
      height: 50px;

      .bgGray {
        flex: 1;
        background-color: #f0f0f0;
        border: none;
        border-radius: 8px;
        padding: 6px 15px;
        font-size: 14px;
        transition: border-color 0.3s ease-in-out;
        height: 36px;
      }

      .lang {
        height: 34px;
        width: 34px;
        margin-right: 12px;
      }
    }
  }

  #container {
    flex: 1;

    .custom-content-marker {
      display: flex;
    }
  }

  .btoLogo {
    display: flex;
    background: #005bcf;
    color: #fff;
    height: 50px;
    align-items: center;

    .logo {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background: #005bcf;
      margin: 0 auto;
      text-align: center;
      // margin-top: -14px;
      position: relative;
      z-index: 999;

      img {
        width: 68px;
        height: 50px;
        padding-top: 14px;
      }
    }

    .flex1 {
      flex: 1;
      text-align: center;

      .site {
        display: inline-flex;
        align-items: center;
        position: relative;

        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }

        .siteText {
          font-size: 14px;
          font-weight: 500;
        }

        .siteNum {
          position: absolute;
          right: -15px;
          top: -10px;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 8px;
          font-size: 12px;
          line-height: 12px;
          padding: 3px 6px;
          height: 12px;
          display: inline-block;
          border-radius: 10px;
        }
      }
    }
  }

  .lineNav {
    position: absolute;
    top: 50px;
    width: 100%;

    .nav {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 0 16px;
      margin: 0px 16px;
      border-radius: 4px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);

      .navFlex1 {
        flex: 1;
      }

      .lineBtn {
        margin-top: 12px;
        padding-bottom: 13px;

        span {
          font-size: 14px;
          color: #323233;
          margin-left: 24px;
        }

        .buttonSelect {
          color: #4396ff;
          border-bottom: 4px solid #4396ff;
          padding-bottom: 10px;
        }
      }
    }

    .lineDetail {
      background: #fff;
      width: 156px;
      float: right;
      margin-right: 16px;
      margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      position: relative;
      padding: 8px 0;

      .close {
        color: #4396ff;
        font-size: 16px;
        position: absolute;
        right: -8px;
        top: -8px;
        background: #fff;
        border-radius: 50%;
      }

      .overflow {
        overflow-y: auto;
        padding: 0px 16px;
        position: relative;

        .mt-8 {
          margin-top: 8px;
        }

        .line {
          position: absolute;
          width: 1px;
          height: 100%;
          background: #c8c9cc;
          left: 6.5px;
          top: 0px;
        }

        .flexLine {
          position: relative;
          overflow: hidden;

          .flex {
            align-items: center;
            font-size: 12px;
            color: #646566;
            position: relative;

            .number {
              width: 13px;
              height: 13px;
              background: #ebf4ff;
              font-weight: 500;
              border-radius: 50%;
              font-size: 12px;
              color: #4396ff;
              line-height: 13px;
              text-align: center;
              margin-right: 6px;
            }

            .time {
              width: 30px;
              line-height: 13px;
              text-align: center;
            }

            .flex1 {
              line-height: 13px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 0;
              margin-left: 8px;
            }
          }
        }

        .currentColor {
          color: #e7741c !important;
          font-weight: 600;

          .number {
            background: #ffe1cb;
            color: #e7741c !important;
          }
        }
      }
    }
  }

  .searchList {
    position: absolute;
    background: #fff;
    width: 100%;
    top: 50px;
    z-index: 9999;
    max-height: 450px;
    overflow-y: auto;

    .searchAddress {
      padding: 12px 16px;
      margin: 0 16px;
      border-bottom: 1px solid #ebedf0;

      .text-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.siteDetail-popup {
  bottom: 90px;
  width: 80%;
  padding: 20px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.11);
  top: auto;
  transform: translate3d(-50%, 0, 0);

  .siteDetail {
    position: relative;

    .close {
      position: absolute;
      right: 0;
      top: -8px;
      font-size: 16px;
      color: #999;
    }

    .label {
      color: #fff;
      font-size: 12px;
      background: #4d88d2;
      border-radius: 2px;
      padding: 1px 4px;
    }

    .flex {
      margin-top: 12px;

      button {
        padding: 2px 16px;
      }
    }
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 !important;

  .titleCol {
    padding: 24px 16px 0;
  }

  .filteredList {
    flex: 1;
    overflow: hidden;

    .filteredListFlex {
      padding: 0 16px;

      height: 100%;
      overflow-y: auto;
    }
  }
}

.siteList {
  padding: 24px 16px;

  .title {
    font-weight: 500;
    font-size: 16px;
    color: #323233;
    line-height: 22px;
  }

  .bgGray {
    border-radius: 50px;
    margin: 20px 0;
    padding: 8px;
    line-height: 20px;
  }

  .list {
    padding: 12px 16px;
    background: #f2f3f5;
    border-radius: 10px;
    margin-bottom: 12px;
  }

  .textCenter {
    text-align: center;
  }
}

.labelTag {
  width: 53px;
  height: 16px;
  background: rgba(77, 136, 210, 0.1);
  border-radius: 9px;
  font-weight: 400;
  font-size: 12px;
  color: #4d88d2;
  padding: 1px 6px;
  margin-right: 8px;
  white-space: nowrap;
}
</style>
