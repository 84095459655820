import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress'
import i18n from './i18n'
import { setDocumentTitle } from '@/plugins/domUtil'
import { APP_LANGUAGE, ACCESS_TOKEN } from '@/store/mutation-types'

NProgress.configure({ showSpinner: false })

const allowList = ['PreviewNotice', 'PreviewNoticeContent', 'PreviewJournalContent', 'PreviewCourse', 'calendarView', 'previewWithdrawal','previewWithdrawalBill','previewSchoolTeams','previewSchoolTeamsHome', 'Bus']
const loginRoutePath = '/login'
const subscribeRoutePath = '/subscribe'
const defaultRoutePath = '/'

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  i18n.locale = to.query.lang || storage.get(APP_LANGUAGE) || 'zh'
  store.dispatch('setLang', i18n.locale);
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(i18n.t('router.' + to.meta.title)))
  /* has token */

  if (allowList.includes(to.name)) {
    next()
  }
  else {
    if (store.getters.token || sessionStorage.getItem(ACCESS_TOKEN)) {
    // if (store.getters.token) {
      next()
    } else {
      if (to.query.state) {
        store.dispatch('setWxState', to.query.state)
      }
      store
        .dispatch('GetInfo', {
          code: to.query.code,
          state: to.query.state,
          token: to.query.token,
          openid: to.query.openid,
          t: to.query.t
        })
        .then((response) => {
          if (response.data.bind == 1) {
            if (to.path === loginRoutePath) {
              next({ path: defaultRoutePath })
              NProgress.done()
            }
            else {
              next()
            }
          }
          else if (response.data.bind == 0) {
            if (to.path != loginRoutePath) {
              next({ path: loginRoutePath })
              NProgress.done()
            }
            else {
              next()
            }
          }
          else if (response.data.bind == 2) {
            if (to.path != subscribeRoutePath) {
              next({ path: subscribeRoutePath })
              NProgress.done()
            }
            else {
              next()
            }
          }
        })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
